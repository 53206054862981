<footer
    class="border-t-2 border-gray-300 bg-white pb-20 pt-8 font-sans text-xs">
    <div class="mx-auto p-4 sm:max-w-lg md:max-w-3xl lg:max-w-4xl xl:max-w-6xl">
        <div class="grid grid-cols-1 md:grid-cols-2">
            <div class="px-3.5 md:place-self-center">
                <div class="text-center md:text-left">
                    <img
                        class="mb-7 inline h-full w-[196px]"
                        src="/assets/images/logo-full.png"
                        alt="logo" />
                </div>
                <div class="grid grid-cols-2 gap-3 font-medium">
                    @for (
                        navigationUnit of navigationUnits$ | async;
                        track $index
                    ) {
                        <ul
                            [ngClass]="
                                $index % 2 === 0
                                    ? 'text-left'
                                    : 'text-right md:text-left'
                            ">
                            @if (navigationUnit.label) {
                                <li
                                    [routerLink]="navigationUnit?.fullPath"
                                    class="block cursor-pointer hover:font-semibold"
                                    aria-label="navItem">
                                    {{ navigationUnit.label | translate }}
                                </li>
                            }
                        </ul>
                    }
                </div>
            </div>
            <div class="px-3.5 pt-[10%] md:place-self-center">
                @for (section of FOOTER_CONTACT_DATA; track $index) {
                    <div class="mb-4 grid grid-cols-2">
                        <p class="text-base font-semibold">
                            {{ section.heading | translate }}
                        </p>
                        <div class="text-right md:text-left">
                            <div [innerHTML]="section.data | translate"></div>
                        </div>
                    </div>
                }
                <div class="text-center md:text-left">
                    <a
                        href="https://www.facebook.com/sharelockapartments/"
                        aria-label="facebookIcon">
                        <mat-icon
                            class="mr-4 !h-9 !w-9 text-4xl !leading-9 text-blue-600">
                            facebook
                        </mat-icon>
                    </a>
                    <a
                        href="https://www.linkedin.com/company/autorun-sp.-z-o.o."
                        aria-label="linkedinIcon">
                        <mat-icon
                            class="!h-9 !w-9 text-4xl text-blue-600"
                            svgIcon="shrl_linkedin"></mat-icon>
                    </a>
                </div>
            </div>
        </div>
        <div class="my-5 text-center">
            {{ COPYRIGHT }}
        </div>
    </div>
</footer>
