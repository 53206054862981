<div class="flex" [class.h-full]="isHorizontal()">
    @for (language of languageUnits$ | async; track $index; let last = $last) {
        <button
            class="font-medium hover:underline"
            [ngClass]="
                language.isSelected
                    ? 'text-shrl-blue-500'
                    : 'text-shrl-zinc-900'
            "
            (click)="changeLang(language.code)">
            {{ language.label }}
        </button>
        @if (!last) {
            <span class="my-auto text-shrl-blue-500">&nbsp;|&nbsp;</span>
        }
    }
</div>
