export interface ICustomIcon {
  name: string;
  route: string;
}

export const CUSTOM_ICONS: ICustomIcon[] = [
  {
    name: 'shrl_linkedin',
    route: '/assets/icons/linkedin.svg',
  },
  {
    name: 'shrl_fibaro',
    route: '/assets/icons/fibaro.svg',
  },
  {
    name: 'shrl_notifications',
    route: '/assets/icons/notifications.svg',
  },
  {
    name: 'shrl_settings',
    route: '/assets/icons/settings.svg',
  },
  {
    name: 'shrl_key_outline',
    route: '/assets/icons/key_outline.svg',
  },
  {
    name: 'shrl_orders',
    route: '/assets/icons/orders.svg',
  },
  {
    name: 'shrl_facebook',
    route: '/assets/icons/facebook.svg',
  },
  {
    name: 'shrl_instagram',
    route: '/assets/icons/instagram.svg',
  },
  // Battery icons to replace by figma ones
  {
    name: 'shrl_battery_low',
    route: '/assets/icons/battery_low.svg',
  },
  {
    name: 'shrl_battery_medium',
    route: '/assets/icons/battery_medium.svg',
  },
  {
    name: 'shrl_battery_high',
    route: '/assets/icons/battery_high.svg',
  },
];
