import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SharelockCommonModule } from '../../../../share/modules/sharelock-common.module';
import { AccountButtonsComponent } from '../components/account-buttons/account-buttons.component';
import { LanguageButtonComponent } from '../components/language-button/language-button.component';
import { SidenavNavigationButtonsComponent } from './sidenav-navigation-buttons/sidenav-navigation-buttons.component';
import { MatIconModule } from '@angular/material/icon';
import { SUPPORT_CONTACT_INFO } from '../../../../share/utils/constans/contact-info';

@Component({
  selector: 'shrl-sidenav',
  standalone: true,
  imports: [
    SharelockCommonModule,
    LanguageButtonComponent,
    AccountButtonsComponent,
    SidenavNavigationButtonsComponent,
    MatIconModule,
  ],
  templateUrl: './sidenav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent {
  readonly SUPPORT_CONTACT_INFO = SUPPORT_CONTACT_INFO;
}
