import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  input,
} from '@angular/core';
import { SharelockCommonModule } from '../../../../../share/modules/sharelock-common.module';
import {
  ENavigationUnitComponentSelection,
  INavigationDataTreeUnit,
} from '../../../../model/navigation.model';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { SharelockAnimations } from '../../../../../share/utils/animations';
import { Router } from '@angular/router';
import { Observable, combineLatest, map, startWith } from 'rxjs';
import { NavigationService } from '../../../../services/navigation.service';
import { AccountDataService } from '../../../../services/auth/account-data.service';
import { isNavUnitVisible } from '../../navigation.utils';
import { IconComponent } from '../../../../../share/components/ui/icon/icon.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'shrl-top-bar-navigation-buttons',
  imports: [
    SharelockCommonModule,
    MatRippleModule,
    MatIconModule,
    IconComponent,
  ],
  templateUrl: './top-bar-navigation-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [SharelockAnimations.appear],
})
export class TopBarNavigationButtonsComponent {
  readonly #destroyRef = inject(DestroyRef);
  readonly #router = inject(Router);
  readonly #accountData = inject(AccountDataService);

  readonly navigationService = inject(NavigationService);

  isHorizontal = input(false);

  navigationUnits$?: Observable<INavigationDataTreeUnit[]>;

  constructor() {
    this.#setNavigationUnitsObservable();
  }

  isActive = (navItem: INavigationDataTreeUnit) =>
    // filter out initial routing
    navItem.fullPath === '/' ? navItem.matchFullPath : navItem.matchPartialPath;

  navigate(navigationUnit: INavigationDataTreeUnit) {
    this.#router.navigateByUrl(navigationUnit?.fullPath);
  }

  #setNavigationUnitsObservable() {
    this.navigationUnits$ = combineLatest([
      this.navigationService.getFilterednavigationUnits$({
        componentSelection: ENavigationUnitComponentSelection.TOPBAR,
      }),
      this.#accountData.isLoggedIn$,
      this.#accountData.userRoles$.pipe(startWith(undefined)),
    ]).pipe(
      takeUntilDestroyed(this.#destroyRef),
      map(([navUnits, userRoles, isLoggedIn]) =>
        navUnits.filter((unit) => isNavUnitVisible(unit, userRoles, isLoggedIn))
      )
    );
  }
}
