import { inject, Injectable } from '@angular/core';
import countriesJsonEn from 'i18n-iso-countries/langs/en.json';
import countriesJsonPl from 'i18n-iso-countries/langs/pl.json';
import languagesJsonEn from '@cospired/i18n-iso-languages/langs/en.json';
import languagesJsonPl from '@cospired/i18n-iso-languages/langs/pl.json';
import * as i18nIsoCountries from 'i18n-iso-countries';
import * as i18nIsoLanguages from '@cospired/i18n-iso-languages';
import { IconRegisterService } from '../../core/services/icon-register/icon-register.service';
import { register } from 'swiper/element';
import { AccountDataService } from '../../core/services/auth/account-data.service';
import { TranslateService } from '@ngx-translate/core';
import { ELanguageCode } from '../utils/enums/country.enum';

export function initializeApp(initializeService: InitializeService) {
  return () => initializeService.initializeApp();
}

@Injectable()
export class InitializeService {
  readonly #iconRegister = inject(IconRegisterService);
  readonly #AccountDataService = inject(AccountDataService);
  readonly #translate = inject(TranslateService);

  initializeApp() {
    this.#registerI18n();
    this.#registerIcons();
    this.#AccountDataService.refreshData();
    this.#registerSwiper();
    this.#setAvailableTranslateLanguages();
  }

  #registerI18n() {
    i18nIsoCountries.registerLocale(countriesJsonEn);
    i18nIsoCountries.registerLocale(countriesJsonPl);
    i18nIsoLanguages.registerLocale(languagesJsonEn);
    i18nIsoLanguages.registerLocale(languagesJsonPl);
  }

  #registerIcons() {
    this.#iconRegister.registerIcons();
  }

  #registerSwiper = () => register();

  #setAvailableTranslateLanguages = () =>
    (this.#translate.langs = [ELanguageCode.PL, ELanguageCode.EN]);
}
