<div class="lg flex h-17 w-full justify-between px-3 md:h-22 3xl:px-28">
    <!-- LEFT CONTAINER -->
    <div #leftContainer class="flex">
        <a [routerLink]="ENavigationRoute.ABOUT_US" aria-label="logo">
            <img
                class="h-full w-40 py-3 md:py-5"
                src="/assets/images/logo-full.png"
                alt="logo" />
        </a>
    </div>
    <!-- EMPTY SPACE -->
    <div #emptySpaceCointainer class="grow"></div>

    <!-- RIGHT CONTAINER -->
    <div #rightContainer class="flex">
        @if (isSidenavActive) {
            <button
                mat-icon-button
                class="my-auto !h-14 !w-14 !text-shrl-blue-500"
                (click)="sidenavToggle()"
                onclick="this.blur()"
                aria-label="Toggle Navigation">
                <mat-icon class="!h-8 !w-8 text-3xl">menu</mat-icon>
            </button>
        } @else if (isSidenavActive === false) {
            <div class="ml-4 mr-10">
                <shrl-top-bar-navigation-buttons
                    class="flex h-full flex-nowrap">
                </shrl-top-bar-navigation-buttons>
            </div>
            <shrl-language-button
                [isHorizontal]="true"
                class="mr-4"></shrl-language-button>
            <shrl-account-buttons [isHorizontal]="true" class="my-auto">
            </shrl-account-buttons>
        }
    </div>
</div>
