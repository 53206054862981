import {
  Component,
  ChangeDetectionStrategy,
  inject,
  DestroyRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { COPYRIGHT } from '../../../share/utils/constans/copyright';
import { SharelockCommonModule } from '../../../share/modules/sharelock-common.module';
import { ENavigationUnitComponentSelection } from '../../model/navigation.model';
import { NavigationService } from '../../services/navigation.service';
import { FOOTER_CONTACT_DATA } from './footer.data';

@Component({
  selector: 'shrl-footer',
  standalone: true,
  imports: [SharelockCommonModule, RouterLink, MatIconModule],
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  readonly #destroyRef = inject(DestroyRef);
  readonly #navigationService = inject(NavigationService);

  readonly COPYRIGHT = COPYRIGHT;
  readonly FOOTER_CONTACT_DATA = FOOTER_CONTACT_DATA;

  readonly navigationUnits$ = this.#navigationService
    .getFilterednavigationUnits$({
      componentSelection: ENavigationUnitComponentSelection.FOOTER,
      hasLabel: true,
      flatten: true,
    })
    .pipe(takeUntilDestroyed(this.#destroyRef));
}
