import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CUSTOM_ICONS } from '../../model/icons.model';

@Injectable({
  providedIn: 'root',
})
export class IconRegisterService {
  readonly #matIconRegistry = inject(MatIconRegistry);
  readonly #domSanitizer = inject(DomSanitizer);

  registerIcons = () => {
    CUSTOM_ICONS.forEach((icon) => {
      this.#matIconRegistry.addSvgIcon(
        icon.name,
        this.#domSanitizer.bypassSecurityTrustResourceUrl(icon.route)
      );
    });
    this.#matIconRegistry.setDefaultFontSetClass('material-symbols-outlined');
  };
}
