import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  input,
} from '@angular/core';
import { SharelockCommonModule } from '../../../../../share/modules/sharelock-common.module';
import { TranslateService } from '@ngx-translate/core';
import { ELanguageCode } from '../../../../../share/utils/enums/country.enum';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LanguageUnit } from '../../../../model/language.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'shrl-language-button',
  imports: [SharelockCommonModule],
  templateUrl: './language-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageButtonComponent {
  readonly #destroyRef = inject(DestroyRef);
  readonly #translate = inject(TranslateService);

  isHorizontal = input.required<boolean>();

  readonly languageUnits$: Observable<LanguageUnit[]> =
    this.#translate.onDefaultLangChange.pipe(
      takeUntilDestroyed(this.#destroyRef),
      map((langChange) => langChange.lang),
      startWith(this.#translate.defaultLang),
      map((lang) => this.#mapLangs(lang as ELanguageCode))
    );

  changeLang(code: ELanguageCode) {
    this.#translate.setDefaultLang(code);
  }

  #mapLangs = (selectedLang: ELanguageCode): LanguageUnit[] =>
    this.#translate
      .getLangs()
      .map((lang) =>
        this.#createLanguageUnit(lang as ELanguageCode, lang === selectedLang)
      );

  #createLanguageUnit = (
    code: ELanguageCode,
    isSelected?: boolean
  ): LanguageUnit => ({
    label: code.toUpperCase(),
    code,
    isSelected,
  });
}
