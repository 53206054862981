import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  provideHttpClient,
  withFetch,
  HttpBackend,
  withInterceptors,
  withInterceptorsFromDi,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import {
  InitializeService,
  initializeApp,
} from './share/services/initialize.service';
import { ELanguageCode } from './share/utils/enums/country.enum';
import { routes } from './shrl.routes';
import { apiInterceptor } from './core/interceptors/api.interceptor';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { apiErrorInterceptor } from './core/interceptors/api-error.interceptor';
import { loaderInterceptor } from './core/interceptors/loader.interceptor';

const SHRL_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/yyyy',
  },
  display: {
    dateInput: 'DD/MM/yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),

    // interceptors order matters!
    provideHttpClient(
      withFetch(),
      withInterceptors([
        apiInterceptor,
        apiErrorInterceptor,
        loaderInterceptor,
      ]),
      withInterceptorsFromDi()
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },

    provideAnimations(),
    provideEnvironmentNgxMask(),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory2,
          deps: [HttpBackend],
        },
        defaultLanguage: ELanguageCode.PL,
      })
    ),

    // MATERIAL
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'dynamic' },
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: SHRL_DATE_FORMATS },

    // CUSTOM INITIALIZER
    InitializeService,
    provideAppInitializer(() => {
      const initializerFn = initializeApp(inject(InitializeService));
      return initializerFn();
    }),
  ],
};

function HttpLoaderFactory2(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
    {
      prefix: './assets/i18n/about-us/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/account/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/faqs/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/guest/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/how-it-works/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/imprint/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/contact/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/property/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/shared/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/support/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/reservations/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/orders/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/dashboard/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/request/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/list-data/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/error/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/discounts/',
      suffix: '.json',
      optional: true,
    },
    {
      prefix: './assets/i18n/subscriptions/',
      suffix: '.json',
      optional: true,
    },
  ]);
}
