import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  inject,
  input,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { SharelockCommonModule } from '../../../../../share/modules/sharelock-common.module';
import { MatButtonModule } from '@angular/material/button';
import { ENavigationRoute } from '../../../../model/navigation.model';
import { AccountDataService } from '../../../../services/auth/account-data.service';
import { SnackBarService } from '../../../../../share/services/utils/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { IAccountDetailsDTO } from '../../../../../features/account/model/account.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'shrl-account-buttons',
  imports: [SharelockCommonModule, MatButtonModule, RouterLink],
  templateUrl: './account-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountButtonsComponent implements OnInit {
  readonly #destroyRef = inject(DestroyRef);
  readonly #router = inject(Router);
  readonly #snackBar = inject(SnackBarService);
  readonly #translate = inject(TranslateService);
  readonly #accountDataService = inject(AccountDataService);
  readonly #cdr = inject(ChangeDetectorRef);

  isHorizontal = input(false);

  ENavigationRoute = ENavigationRoute;
  accountData: IAccountDetailsDTO | null = null;

  ngOnInit() {
    this.#setAccountDataSubscriber();
  }

  logOut() {
    this.#accountDataService.logOut();
    this.#router.navigate([ENavigationRoute.LOGIN]);
    this.#snackBar.openSnackbarSuccess({
      label: this.#translate.instant('SHARED.GENERAL.LOGGED_OUT'),
    });
  }

  #setAccountDataSubscriber = () =>
    this.#accountDataService.accountData$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((data) => {
        this.accountData = data;
        this.#cdr.detectChanges();
      });
}
