import { Component } from '@angular/core';
import { FrameComponent } from './core/components/frame/frame.component';
import { SharelockCommonModule } from './share/modules/sharelock-common.module';

@Component({
  standalone: true,
  selector: 'shrl-root',
  imports: [SharelockCommonModule, FrameComponent],
  templateUrl: './shrl.component.html',
  styleUrl: './shrl.component.scss',
})
export class ShrlComponent {}
