import {
  HttpInterceptorFn,
  HttpRequest,
  HttpHandlerFn,
  HttpEvent,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { SnackBarService } from '../../share/services/utils/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import {
  IHttpErrorDTO,
  IHttpErrorOld,
  IHttpErrorResponseDetails,
  IHttpResponse,
} from '../model/api/http-response.model';

const getErrorCode = (code: string) => `ERROR.API.${code}`;

const mapErrors = (errorDtos: IHttpErrorDTO[] | null) =>
  errorDtos?.map((error) => error.errors.map((err) => err.code)).flat();

const translateErrorCodes = (
  codes: string[] | undefined,
  translate: TranslateService
) => codes?.map((code) => translate.instant(getErrorCode(code))).join(', ');

const getErrorMessages = (
  errors: IHttpErrorDTO[] | null,
  translate: TranslateService
) => translateErrorCodes(mapErrors(errors), translate);

export const apiErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const snackBar = inject(SnackBarService);
  const translate = inject(TranslateService);

  return next(req).pipe(
    catchError((errorResponse: IHttpErrorResponseDetails) => {
      if (errorResponse.status === 429) {
        snackBar.openSnackbarError(
          {
            label: translate.instant('ERROR.GENERAL.TOO_MANY_REQUESTS'),
            actionLabel: translate.instant('SHARED.GENERAL.OK_UNDERSTAND'),
          },
          20000
        );
      } else if (errorResponse.status === 400) {
        if ((errorResponse.error as IHttpResponse<unknown>)?.errors) {
          snackBar.openSnackbarError({
            label: getErrorMessages(
              (errorResponse.error as IHttpResponse<unknown>)?.errors,
              translate
            ),
          });
        } else if ((errorResponse.error as IHttpErrorOld)?.errorKey) {
          snackBar.openSnackbarError({
            label: translate.instant(
              getErrorCode(
                (errorResponse.error as IHttpErrorOld).errorKey ?? ''
              )
            ),
          });
        } else if ((errorResponse.error as IHttpErrorOld)?.message) {
          snackBar.openSnackbarError({
            label: translate.instant(
              getErrorCode((errorResponse.error as IHttpErrorOld).message ?? '')
            ),
          });
        }
      } else if (
        errorResponse.status &&
        [500, 404].includes(errorResponse.status)
      ) {
        snackBar.openSnackbarError();
      }
      return throwError(() => errorResponse);
    })
  );
};
