<div class="flex text-nowrap" [class.flex-col]="!isHorizontal()">
    @if (accountData) {
        <span
            class="shrl-text-body-1 rounded bg-shrl-teal-500 px-4 py-1"
            [ngClass]="isHorizontal() ? 'my-auto' : 'mr-auto mt-2'">
            {{ accountData.email }}
        </span>
        <button
            (click)="logOut()"
            mat-flat-button
            [ngClass]="isHorizontal() ? 'my-auto ml-4' : 'mr-auto mt-2'"
            class="shrl-button-normal-flat">
            {{ 'SHARED.BUTTON.LOG_OUT' | translate }}
        </button>
    } @else {
        <button
            mat-flat-button
            [routerLink]="[ENavigationRoute.LOGIN]"
            [ngClass]="isHorizontal() ? 'my-auto' : 'mr-auto'"
            class="shrl-button-normal-flat">
            {{ 'SHARED.GENERAL.LOG_IN' | translate }}
        </button>

        <button
            mat-flat-button
            [routerLink]="[ENavigationRoute.REGISTER]"
            [ngClass]="isHorizontal() ? 'my-auto ml-4' : 'mr-auto mt-2'"
            class="!shrl-button-normal !bg-shrl-teal-500">
            {{ 'SHARED.GENERAL.REGISTER' | translate }}
        </button>
    }
</div>
