import {
  Component,
  ChangeDetectionStrategy,
  inject,
  DestroyRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import {
  BehaviorSubject,
  combineLatest,
  delay,
  filter,
  map,
  startWith,
} from 'rxjs';
import {
  SharelockAnimations,
  AnimationStateE,
} from '../../../../../share/utils/animations';
import { SharelockCommonModule } from '../../../../../share/modules/sharelock-common.module';
import {
  INavigationDataTreeUnit,
  ENavigationUnitComponentSelection,
} from '../../../../model/navigation.model';
import { NavigationService } from '../../../../services/navigation.service';
import { Router } from '@angular/router';
import { AccountDataService } from '../../../../services/auth/account-data.service';
import { MatRippleModule } from '@angular/material/core';
import { isNavUnitVisible } from '../../navigation.utils';
import { IconComponent } from '../../../../../share/components/ui/icon/icon.component';

@Component({
  selector: 'shrl-sidenav-navigation-buttons',
  imports: [
    SharelockCommonModule,
    MatIconModule,
    IconComponent,
    MatRippleModule,
    MatIconModule,
  ],
  templateUrl: './sidenav-navigation-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [SharelockAnimations.appear, SharelockAnimations.transformX],
})
export class SidenavNavigationButtonsComponent {
  readonly #destroyRef = inject(DestroyRef);
  readonly #navigationService = inject(NavigationService);
  readonly #router = inject(Router);
  readonly #accountData = inject(AccountDataService);

  AnimationStateE = AnimationStateE;

  #navigationUnits?: INavigationDataTreeUnit[];
  #selectedUnits?: INavigationDataTreeUnit[];

  displayedUnits?: INavigationDataTreeUnit[] = this.#selectedUnits;
  parent?: INavigationDataTreeUnit;
  parents?: INavigationDataTreeUnit[];

  #animationState$ = new BehaviorSubject<AnimationStateE>(AnimationStateE.SHOW);
  animationState$ = this.#animationState$.asObservable();

  constructor() {
    this.#setNavigationServiceSubscribers();
  }

  // ON CLICK ACTIONS
  onNavigationLabelClick(navigationUnit: INavigationDataTreeUnit) {
    if (navigationUnit.isRedirected) {
      this.onNavigationArrowClick(navigationUnit);
    } else if (navigationUnit?.fullPath) {
      this.#router.navigateByUrl(navigationUnit.fullPath);
    }
  }

  onNavigationArrowClick(navigationUnit: INavigationDataTreeUnit) {
    if (navigationUnit?.navigationChildren?.length) {
      this.#animationState$.next(AnimationStateE.HIDE);
      this.#selectedUnits = navigationUnit.navigationChildren;
    } else {
      this.onNavigationLabelClick(navigationUnit);
    }
  }

  // SETTERS
  selectParents() {
    this.#animationState$.next(AnimationStateE.HIDE);
    this.#selectedUnits = this.parents;
  }

  setDisplayTree() {
    this.displayedUnits = this.#selectedUnits;
    this.parent = this.#getParent(
      this.#navigationUnits,
      this.displayedUnits?.at(0)
    );
    this.parents = this.#getLeafs(this.parent, this.#navigationUnits);
    this.#animationState$.next(AnimationStateE.SHOW);
  }

  #setNavigationUnits = (navigationUnits: INavigationDataTreeUnit[]) => {
    this.#navigationUnits = navigationUnits;
    this.#selectedUnits = this.#getActiveNavigationUnits(this.#navigationUnits);
    if (!this.#selectedUnits?.length) {
      this.#selectedUnits = this.#navigationUnits;
    }
    this.setDisplayTree();
  };

  #resetNavigationUnits = () => {
    this.#setNavigationUnits(this.#navigationUnits ?? []);
  };

  #setNavigationServiceSubscribers() {
    combineLatest([
      this.#navigationService.getFilterednavigationUnits$({
        componentSelection: ENavigationUnitComponentSelection.SIDENAV,
        hasLabel: true,
      }),
      this.#accountData.isLoggedIn$,
      this.#accountData.userRoles$.pipe(startWith(undefined)),
    ])
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        map(([navUnits, isLoggedIn, userRoles]) =>
          navUnits.filter((unit) =>
            isNavUnitVisible(unit, isLoggedIn, userRoles)
          )
        )
      )
      .subscribe(this.#setNavigationUnits);

    this.#navigationService.sidenavOpened$
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        filter((opened) => !opened),
        delay(500)
      )
      .subscribe(this.#resetNavigationUnits);
  }

  // UTILS
  #getActiveNavigationUnits(
    units: INavigationDataTreeUnit[]
  ): INavigationDataTreeUnit[] {
    const isActiveInCurrentLevel = units.some((unit) => unit.matchFullPath);

    if (isActiveInCurrentLevel) {
      return units;
    }

    let activeUnits: INavigationDataTreeUnit[] = [];
    units.forEach((unit) => {
      if (unit.navigationChildren && unit.navigationChildren.length > 0) {
        const childActiveUnits = this.#getActiveNavigationUnits(
          unit.navigationChildren
        );
        activeUnits = activeUnits.concat(childActiveUnits);
      }
    });

    return activeUnits;
  }

  #getLeafs(
    leaf?: INavigationDataTreeUnit,
    units?: INavigationDataTreeUnit[]
  ): INavigationDataTreeUnit[] | undefined {
    if (!units || !leaf) {
      return undefined;
    }

    if (units.some((unit) => unit === leaf)) {
      return units;
    }

    let foundLeafs: INavigationDataTreeUnit[] = [];
    units.forEach((unit) => {
      const leafs = this.#getLeafs(leaf, unit?.navigationChildren);
      if (leafs) {
        foundLeafs = foundLeafs.concat(leafs);
      }
    });
    return foundLeafs.length > 0 ? foundLeafs : undefined;
  }

  #getParent(
    roots?: INavigationDataTreeUnit[],
    child?: INavigationDataTreeUnit
  ): INavigationDataTreeUnit | undefined {
    if (!roots || roots.length === 0 || !child) {
      return undefined;
    }

    for (const root of roots) {
      if (root.navigationChildren && root.navigationChildren.includes(child)) {
        return root;
      }
      const parent = this.#getParent(root.navigationChildren || [], child);
      if (parent) {
        return parent;
      }
    }
    return undefined;
  }
}
